<template>
<div :key="`key_${title}_${key}`">
    <CourseSubHeader
        :show-marking-period-picker="true"
        :increment-key="incrementKey"
    >
        <template #buttons>
            <a
                href="#"
                title="Export To CSV"
                class="btn kt-subheader__btn-primary btn-icon"
                @click.stop.prevent="exportData"
            >
                <i class="flaticon-download-1" />
            </a>
        </template>
        <template #additional-links />
    </CourseSubHeader>

    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid pr-2 pl-4">
        <div class="kt-portlet p-0 m-0">
            <div class="kt-portlet__body p-0 m-0">
                <CourseAveragesHotTable
                    v-if="isPortfolioReady && dataSource.length > 0"
                    :key="`key_${key}`"
                    ref="wrapperAverages"
                    :columns="gradingColumns"
                    :data-source="dataSource"
                />
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import moment from 'moment';
import Vue from 'vue';
import Types from '../store/Types';
import portfolioMixins from '../store/mixins/portfolioMixins';
import courseMixins from '../store/mixins/courseMixins';
import averagingMixins from '../store/mixins/averagingMixins';
import studentMixins from '../store/mixins/studentMixins';
import CourseSubHeader from './CourseSubHeader.vue';
import CourseAveragesHotTable from '../components/CourseAveragesHotTable.vue';

export default Vue.extend({
    name: 'CourseAverages',
    components: {
        CourseSubHeader,
        CourseAveragesHotTable,
    },
    mixins: [
        portfolioMixins,
        courseMixins,
        studentMixins,
        averagingMixins,
    ],
    data() {
        return {
            saving: false,
            key: 1,
        };
    },
    computed: {
        isPortfolioReady() {
            const cacheKey = this.$_portfolioMixins_getPortfolioCacheKey;
            return Boolean(this.$store.state.database.cache.find((c) => c.key == cacheKey && c.status == 'cached'));
        },
        schoolTermMarkingPeriodId: {
            get() {
                return this.$store.state.settings.schoolTermMarkingPeriodId;
            },
            set(schoolTermMarkingPeriodId) {
                this.$store.commit(Types.mutations.SAVE_SETTING_SCHOOL_TERM_MARKING_PERIOD, schoolTermMarkingPeriodId);
                this.key += 1;
            },
        },
        extCourseSectionId() {
            return this.$route.params.extCourseSectionId;
        },
        showScaledScores() {
            if (!this.gradeTemplate) return false;
            const { schoolScaledDisplayPreference } = this.gradeTemplate;
            return ['ShowScale', 'All'].includes(schoolScaledDisplayPreference);
        },
        showPercentages() {
            if (!this.gradeTemplate) return false;
            const { schoolScaledDisplayPreference } = this.gradeTemplate;
            return ['ShowPercentage', 'All'].includes(schoolScaledDisplayPreference);
        },
        dataSource() {
            const rows = [];
            const { markingPeriods, gradeCategories, studentAverages } = this;
            const { gradeTemplate, students, schoolTermMarkingPeriodId } = this;
            const { showPercentages, showScaledScores } = this;
            if (!gradeTemplate || students.length == 0 || studentAverages.length == 0) return [];
            students.forEach((student) => {
                const { courseSection, studentEnrollmentId, courseSectionStudentId } = student;
                const { courseSectionId } = courseSection;

                const row = {
                    student: { // for HotCellStudentDetails
                        courseSection,
                        courseSectionId,
                        courseSectionStudentId,
                        student,
                        studentEnrollmentId,
                        name: `${student.lastName}, ${student.firstName}`,
                    },
                };
                const averages = studentAverages.filter((a) => a.courseSectionStudentId == courseSectionStudentId);

                markingPeriods
                    .filter((mp) => schoolTermMarkingPeriodId == null || schoolTermMarkingPeriodId == mp.schoolTermMarkingPeriodId)
                    .forEach((mp) => {
                        const mpAverage = averages.find((a) => a.schoolTermMarkingPeriodId == mp.schoolTermMarkingPeriodId);
                        if (mpAverage) {
                            const averageCalculation = mpAverage.averageCalculation.markingPeriod;
                            row[`schoolTermMarkingPeriodId.${mp.schoolTermMarkingPeriodId}`] = {
                                numerator: mpAverage.sumCategoryEarned,
                                demoninator: mpAverage.sumCategoryWorth,
                                average: averageCalculation.average,
                                cellColor: averageCalculation.scaled ? averageCalculation.scaled.color : 'Grey',
                                scaledMark: averageCalculation.scaled ? averageCalculation.scaled.mark : '',
                                courseSectionId,
                                studentEnrollmentId,
                                showPercentages,
                                showScaledScores,
                                schoolTermMarkingPeriodId: mp.schoolTermMarkingPeriodId,
                                gradeTemplateCategoryId: 0,
                                averageCalculation,
                            };
                            gradeCategories.forEach((c) => {
                                const categoryAvg = averageCalculation.categories.find((x) => x.gradeTemplateCategoryId == c.gradeTemplateCategoryId);
                                if (categoryAvg) {
                                    row[`gradeTemplateCategoryId.${c.gradeTemplateCategoryId}.${mp.schoolTermMarkingPeriodId}`] = {
                                        numerator: categoryAvg.categoryEarned,
                                        demoninator: categoryAvg.categoryWorth,
                                        average: categoryAvg.percentEarned,
                                        cellColor: categoryAvg.scaled ? categoryAvg.scaled.color : 'Grey',
                                        scaledMark: categoryAvg.scaled ? categoryAvg.scaled.mark : '',
                                        courseSectionId,
                                        studentEnrollmentId,
                                        showPercentages,
                                        showScaledScores,
                                        schoolTermMarkingPeriodId: mp.schoolTermMarkingPeriodId,
                                        gradeTemplateCategoryId: c.gradeTemplateCategoryId,
                                        averageCalculation,
                                    };
                                } else {
                                    row[`gradeTemplateCategoryId.${c.gradeTemplateCategoryId}.${mp.schoolTermMarkingPeriodId}`] = {
                                        numerator: null,
                                        demoninator: null,
                                        average: null,
                                        cellColor: 'Grey',
                                        courseSectionId,
                                        studentEnrollmentId,
                                        schoolTermMarkingPeriodId: mp.schoolTermMarkingPeriodId,
                                        gradeTemplateCategoryId: c.gradeTemplateCategoryId,
                                        averageCalculation,
                                    };
                                }
                            });
                        } else {
                            row[`schoolTermMarkingPeriodId.${mp.schoolTermMarkingPeriodId}`] = {
                                numerator: null,
                                demoninator: null,
                                average: null,
                                cellColor: 'Grey',
                                courseSectionId,
                                studentEnrollmentId,
                                schoolTermMarkingPeriodId: mp.schoolTermMarkingPeriodId,
                                gradeTemplateCategoryId: null,
                                averageCalculation: null,
                            };
                            gradeCategories.forEach((c) => {
                                row[`gradeTemplateCategoryId.${c.gradeTemplateCategoryId}.${mp.schoolTermMarkingPeriodId}`] = {
                                    numerator: null,
                                    demoninator: null,
                                    average: null,
                                    cellColor: 'Grey',
                                    courseSectionId,
                                    studentEnrollmentId,
                                    schoolTermMarkingPeriodId: mp.schoolTermMarkingPeriodId,
                                    gradeTemplateCategoryId: c.gradeTemplateCategoryId,
                                    averageCalculation: null,
                                };
                            });
                        }
                    });
                rows.push(row);
            });
            return rows;
        },
        course() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        students() {
            if (!this.course) return [];
            const students = this.$_studentMixins_getStudentsForCourse(this.course);
            return students;
        },
        markingPeriods() {
            return this.$store.state.database.markingPeriods
                .filter((mp) => !mp.deleted)
                .sort((a, b) => ((a.markingPeriod > b.markingPeriod) ? 1 : ((b.markingPeriod > a.markingPeriod) ? -1 : 0)));
        },
        gradeTemplate() {
            if (!this.course) return null;
            return this.course.gradeTemplate;
        },
        studentAverages() {
            const { course } = this;
            if (!course) return [];
            return this.$_averagingMixins_getAveragesForCourse(course);
        },
        title() {
            return this.$route.name;
        },
        gradeCategories() {
            if (!this.gradeTemplate) return [];
            return this.gradeTemplate.categories;
        },
        gradingColumns() {
            const { markingPeriods, gradeCategories, schoolTermMarkingPeriodId } = this;
            const gradingColumns = [];
            markingPeriods
                .filter((mp) => schoolTermMarkingPeriodId == null || schoolTermMarkingPeriodId == mp.schoolTermMarkingPeriodId)
                .forEach((mp) => {
                    gradingColumns.push({
                        key: `schoolTermMarkingPeriodId.${mp.schoolTermMarkingPeriodId}`,
                        title: `MP ${mp.markingPeriod} <br />Average`,
                        schoolTermMarkingPeriodId: mp.schoolTermMarkingPeriodId,
                        gradeTemplateCategoryId: null,
                    });
                    gradeCategories.forEach((c) => {
                        gradingColumns.push({
                            key: `gradeTemplateCategoryId.${c.gradeTemplateCategoryId}.${mp.schoolTermMarkingPeriodId}`,
                            title: `MP ${mp.markingPeriod} <br />${c.categoryName}`,
                            schoolTermMarkingPeriodId: mp.schoolTermMarkingPeriodId,
                            gradeTemplateCategoryId: c.gradeTemplateCategoryId,
                        });
                    });
                });
            return gradingColumns;
        },
        averagesCached() {
            const { extCourseSectionId } = this;
            const { schoolTermMarkingPeriodId } = this.$store.state.settings;
            const cache = this.$store.state.database.cache.find((c) => c.key == `portfolioCalc_${extCourseSectionId}_${schoolTermMarkingPeriodId}`) || null;
            return Boolean(cache);
        },
    },
    watch: {
        isPortfolioReady() {
            this.calculateAverages();
        },
        extCourseSectionId() {
            this.calculateAverages();
        },
        schoolTermMarkingPeriodId() {
            this.key += 1;
            this.calculateAverages();
        },
    },
    mounted() {
        this.calculateAverages();
    },
    methods: {
        incrementKey() {
            this.key += 1;
        },
        calculateAverages() {
            const { extCourseSectionId, isPortfolioReady, averagesCached } = this;
            if (!isPortfolioReady) return;
            if (!averagesCached) {
                this.$_averagingMixins_calculateAverageByExtCourseSectionId(extCourseSectionId);
            }
        },
        exportData() {
            const { gradingColumns } = this;
            const csvColumns = [{ key: 'student', title: 'Student' }, ...gradingColumns];
            // eslint-disable-next-line no-underscore-dangle
            const { data } = this.$refs.wrapperAverages.$refs.wrapper._props.settings;
            const labelsData = csvColumns.map((column) => column.key);
            let csv = csvColumns
                .map((header) => header.title.replaceAll('<br />', ''))
                .join(',');
            csv += '\n';
            data.forEach((row) => {
                csv += labelsData
                    .map((cell) => {
                        if (row[cell]) {
                            // console.log('row[cell', cell, row[cell]);
                            if (row[cell].name) return `\"${row[cell].name}\"`;
                            if (row[cell].showScaledScores) return row[cell].scaledMark;
                            if (row[cell].average !== null) return `${(row[cell].average * 100)}`;
                            return row[cell];
                        }
                        return '';
                    })
                    .join(',');
                csv += '\n';
            });

            const date = moment(new Date()).format('YYYYMMDD');
            const filename = `${this.course.name}_Averages_${date}`.replaceAll(' ', '_');
            const anchor = document.createElement('a');
            anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
            anchor.target = '_blank';
            anchor.download = `${filename}.csv`;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
        },
    },
});

</script>

<style>

div.hot-table-container table td div.grade-cell span {
    font-size: 1.2rem;
    line-height: 1.2rem;
    margin-top: 4px;
    display: block;
    padding: 5px 4px 4px 4px;
    text-align: center;
    height: 28px;
}

</style>
