var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { key: `key_${_vm.title}_${_vm.key}` },
    [
      _c("CourseSubHeader", {
        attrs: {
          "show-marking-period-picker": true,
          "increment-key": _vm.incrementKey,
        },
        scopedSlots: _vm._u([
          {
            key: "buttons",
            fn: function () {
              return [
                _c(
                  "a",
                  {
                    staticClass: "btn kt-subheader__btn-primary btn-icon",
                    attrs: { href: "#", title: "Export To CSV" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.exportData.apply(null, arguments)
                      },
                    },
                  },
                  [_c("i", { staticClass: "flaticon-download-1" })]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "additional-links",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        {
          staticClass:
            "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid pr-2 pl-4",
        },
        [
          _c("div", { staticClass: "kt-portlet p-0 m-0" }, [
            _c(
              "div",
              { staticClass: "kt-portlet__body p-0 m-0" },
              [
                _vm.isPortfolioReady && _vm.dataSource.length > 0
                  ? _c("CourseAveragesHotTable", {
                      key: `key_${_vm.key}`,
                      ref: "wrapperAverages",
                      attrs: {
                        columns: _vm.gradingColumns,
                        "data-source": _vm.dataSource,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }