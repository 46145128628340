var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.dataSource.length > 0
    ? _c(
        "div",
        { key: `key_${_vm.key}`, staticClass: "w-100 hot-table-container" },
        [
          _c(
            "hot-table",
            { ref: "wrapper", attrs: { settings: _vm.hotSettings } },
            [
              _c(
                "hot-column",
                {
                  attrs: {
                    title: "Student",
                    width: "180",
                    "read-only": true,
                    settings: {
                      data: `student`,
                    },
                  },
                },
                [
                  _c("HotCellStudentDetails", {
                    attrs: { "hot-renderer": "" },
                  }),
                ],
                1
              ),
              _vm._l(_vm.columns, function (col) {
                return _c("hot-column", {
                  key: col.key,
                  attrs: {
                    "read-only": true,
                    width: 100,
                    title: col.title,
                    settings: {
                      data: col.key,
                      className: "today-column",
                    },
                    renderer: _vm.averageRenderer,
                  },
                })
              }),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }