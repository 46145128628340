<template>
<div
    v-if="dataSource.length > 0"
    :key="`key_${key}`"
    class="w-100 hot-table-container"
>
    <hot-table
        ref="wrapper"
        :settings="hotSettings"
    >
        <hot-column
            title="Student"
            width="180"
            :read-only="true"
            :settings="{
                data: `student`,
            }"
        >
            <HotCellStudentDetails hot-renderer />
        </hot-column>

        <hot-column
            v-for="col in columns"
            :key="col.key"
            :read-only="true"
            :width="100"
            :title="col.title"
            :settings="{
                data: col.key,
                className: 'today-column',
            }"
            :renderer="averageRenderer"
        />
    </hot-table>
</div>
</template>

<script lang="ts">
import Vue from 'vue';
import HotCellStudentDetails from './HotCellStudentDetails.vue';
import { hotDefaults } from '../store/hotDefaults';
import hotTableMixins from '../store/mixins/hotTableMixins';

export default Vue.extend({
    name: 'CourseAveragesHotTable',
    components: {
        HotCellStudentDetails,
    },
    mixins: [hotTableMixins],
    props: {
        columns: {
            type: Array,
            required: true,
        },
        dataSource: {
            type: Array,
            required: true,
        },
    },
    data() {
        const data = {
            key: 1,
            hotSettings: {
                autoColumnSize: false,
                manualColumnResize: true,
                colHeaders: true,
                autoRowSize: false,
                columnHeaderHeight: 38,
                data: [],
                fixedColumnsStart: 1,
                rowHeaders: true,
                rowHeaderWidth: 40,
                rowHeights: 50,
            },
        };

        data.hotSettings = {
            ...hotDefaults,
            ...data.hotSettings,
        };

        return data;
    },
    computed: {
        extCourseSectionId() {
            return this.$route.params.extCourseSectionId;
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        populate() {
            const { dataSource } = this;
            this.hotSettings.height = document.documentElement.clientHeight - 160;
            this.hotSettings.data = dataSource;

            this.hotSettings.afterGetRowHeader = function (idx, ele) {
                // vert center row #
                const div = ele.firstChild;
                div.className = 'mt-3';

                const row = dataSource[idx];
                const { student } = row;
                if (student && student.courseSection) {
                    const { courseSectionHexColor } = student.courseSection;
                    if (courseSectionHexColor) {
                        // https://stackoverflow.com/a/66143374
                        const setOpacity = (hex, alpha) => `${hex}${Math.floor(alpha * 255).toString(16).padStart(2, '0')}`;
                        div.parentNode.style = `background-color: ${setOpacity(courseSectionHexColor, 0.2)}`;
                    }
                }
            };

            this.key += 1; // force refresh
        },
        averageRenderer(instance, td, row, col, prop, dataSource) {
            const { $router, extCourseSectionId } = this;
            const element = td;

            const {
                showScaledScores,
                showPercentages,
                scaledMark,
                studentEnrollmentId,
                average,
                cellColor,
            } = dataSource;

            let val = '';

            if (showPercentages) {
                val += `${Math.round(average * 100)}%`;
            }
            if (showScaledScores && scaledMark) {
                if (val) val += '\n';
                val += `${scaledMark}`;
            }
            this.$_hotTableMixins_setMarkProperties(element, val, cellColor);

            const anchor = document.createElement('a');
            anchor.href = '#';
            anchor.addEventListener('mousedown', (event) => {
                event.preventDefault();

                $router.push({
                    name: 'StudentCourseOverview',
                    params: {
                        studentEnrollmentId,
                        extCourseSectionId,
                    },
                });
            });

            anchor.innerText = val;
            element.replaceChildren(anchor);

            element.style.fontSize = '1.1rem';
            element.style.fontWeight = '500';
            element.style.color = 'rgb(48, 46, 61)';
        },
    },
});

</script>

<style scoped src="../css/colors.scss" lang="scss" />
